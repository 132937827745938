// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$indigo-palette: (
    50: #e8eaf6,
    100: #c5cae9,
    200: #9fa8da,
    300: #7986cb,
    // ... continues to 900
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        // ... continues to 900
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$cg-palette-primary: (
    50: #e1e3eb,
    100: #b3bacd,
    200: #818cab,
    300: #4e5d89,
    400: #283b70,
    500: #021857,
    600: #021550,
    700: #011146,
    800: #010e3d,
    900: #01082c,
    A100: #3a6bff,
    A200: #0745ff,
    A400: #0035d3,
    A700: #002eb9,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$cg-palette-secondary: (
    50: #EBF3FA,
    100: #CEE1F2,
    200: #ADCDEA,
    300: #8CB9E2,
    400: #74AADB,
    500: #5B9BD5,
    600: #5393D0,
    700: #4989CA,
    800: #407FC4,
    900: #2F6DBA,
    A100: #FEFFFF,
    A200: #CBE1FF,
    A400: #98C4FF,
    A700: #7FB6FF,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$cg-palette-accent: (
    50: #E0F5F1,
    100: #B3E6DD,
    200: #80D5C7,
    300: #4DC4B0,
    400: #26B89F,
    500: #00AB8E,
    600: #00A486,
    700: #009A7B,
    800: #009171,
    900: #00805F,
    A100: #ADFFE6,
    A200: #7AFFD6,
    A400: #47FFC7,
    A700: #2EFFBF,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$ccm_saas_portal-primary: mat.define-palette($cg-palette-primary);
$ccm_saas_portal-secondary: mat.define-palette($cg-palette-secondary);
$ccm_saas_portal-accent: mat.define-palette($cg-palette-accent);

// The warn palette is optional (defaults to red).
$ccm_saas_portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ccm_saas_portal-theme: mat.define-light-theme((color: (primary: $ccm_saas_portal-primary,
                secondary: $ccm_saas_portal-secondary,
                accent: $ccm_saas_portal-accent,
                warn: $ccm_saas_portal-warn,
            )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ccm_saas_portal-theme);


@import "../node_modules/bootstrap/scss/functions";


@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/close";

@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";



html,
body {
    height: 100%;
    width: 100%;
    background-color: #f4f5f5;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

p {
    margin: 0px;
}

// .toast {
// --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0);
// }
// .toast-header {
//     color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
// }
.toast-container {
    --bs-toast-zindex: 1090;
    position: absolute;
    z-index: var(--bs-toast-zindex);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none;

}

//   .position-fixed {
//     position: fixed !important;
//   }

$accentdark: #00AB8E;
/* Mostly all the buttons */
$accentlight: #021857;
/* Mostly all the menu backgrounds */
$hoverlight: #5B9BD5;
/* Mostly hover itmes */
$disabledDark: #75787B;
$tableHeader1: #B0B4C3;
$tableHeader2: #E3E7EB;


$acc1: #5B9BD5;
$acc2: #00AB8E;
$acc3: #021857;
$acc4: #75787B;
$negValue: #ff2f2f;

@font-face {
    font-family: 'Codec Bold';
    src: url(./assets/Fonts/Codec-Cold-Bold-trial.ttf);
}

@font-face {
    font-family: 'Codec Normal';
    src: url(./assets/Fonts/Codec-Cold-Regular-trial.ttf);
}

.h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    user-select: none;
    color: #343741;
    font-family: Codec Normal;
    margin-top: 0px;
}

h3 {
    color: $accentlight;
    font-family: Codec Normal;
    font-size: 28px;
    padding: 0px;
    margin: 0px;
    user-select: none;
}

.paragraph {
    padding: 0px;
    margin: 0px;
}





.mainFooterSpace {
    width: 100%;
    height: 30px;
    left: 0;
    bottom: 0;
    padding-top: 0px;
}

.mainFooter {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 30px;
    background-color: $accentlight;
    left: 0;
    bottom: 0;
}

.footerParagraph {
    font-family: 'Codec Normal';
    text-align: center;
    color: white;
    font-size: x-small;
    vertical-align: middle;
    margin-top: 5px;
    margin-bottom: 2px;
    user-select: none;
}

.footerParagraphPopia {
    font-family: 'Verdana';
    text-align: right;
    color: white;
    font-size: x-small;
    vertical-align: middle;
    margin-top: 5px;
    margin-bottom: 2px;
    margin-right: 5px;
    user-select: none;
    cursor: pointer;
}

.popiaPopup {
    width: 100%;
}

.popiaTable {
    vertical-align: top;
}

.mainHeader {
    position: fixed;
    background-color: $accentlight;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 26px;
    font-weight: bold;
    color: white;
    z-index: 2;
    top: 0;
    left: 0;
    user-select: none;
}

.mainHeaderSpace {

    height: 50px;
    min-height: 50px;
    max-height: 50px;
    width: 100%;
    top: 0;
    left: 0;
}

.mainHeaderText {
    margin: 15px;
    font-family: 'Codec Bold';
}

.mainHeaderFlex {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.mainSiteTable {
    height: calc(100vh - 80px);
    vertical-align: top;
}

.TopBarLeftSection {
    display: flex;
    top: 0;
    left: 0;
    z-index: 2;
    position: fixed;
    margin-top: 0px;
    flex-wrap: nowrap;
}

.navIcon {
    margin-left: 0px;
    margin-top: 0px;
    font-size: 28px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    color: #ffffff;
    top: 0;

    &:hover {
        background: $hoverlight;
        color: white;
        cursor: pointer;
    }

    &:focus {
        color: $accentdark;
    }
}

.userIcon {
    margin-left: 0px;
    margin-top: 0px;
    font-size: 28px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    top: 0;
    position: fixed;
    right: 0;
    z-index: 8;

    &:hover {
        background: $hoverlight;
        color: white;
        cursor: pointer;
    }

    &:focus {
        color: #ffffff;
    }
}

.notificationIcon {
    margin-left: 0px;
    margin-top: 0px;
    font-size: 28px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    top: 0;
    position: fixed;
    right: 50px;
    z-index: 8;

    &:hover {
        background: $hoverlight;
        color: white;
        cursor: pointer;
    }

    &:focus {
        color: #ffffff;
    }
}

.helpIcon {
    margin-left: 0px;
    margin-top: 0px;
    font-size: 28px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    top: 0;
    position: fixed;
    right: 100px;
    z-index: 8;

    &:hover {
        background: $hoverlight;
        color: white;
        cursor: pointer;
    }

    &:focus {
        color: #ffffff;
    }
}

.portalMainTable {
    border: none;
    background-color: rgb(230, 230, 230);
}

.portalMainTableRow {
    border: none;
}

.portalMainTableCenter {
    background-color: white;
    border: none;
    padding: 0px;
    margin: 0px;
    width: 100%;
}

.userNav {
    background: white !important;
    margin-top: 5px;
    margin-right: 5px;
}

.sidebarWrap {
    background: #f1f1f1;
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: max-content;
}

.userMenuTopLine {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: small;
}

.userMenuTopLineItem {
    padding: 16px 2px 5px 10px;
    user-select: none;
    font-weight: bold;
}

.userMenuTopLineItemSignout {
    padding: 5px 10px 5px 10px;

}

// .userMenuTopLineItemSignout:hover {
//     background-color: #d1d1d1;

// }

.userMenuProfile {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: $accentlight;

}

.userMenuProfileItems {
    padding: 0px;
    padding-left: 5px !important;
    margin: 0px;
    user-select: none;
}

.userMenuProfileItemsName {
    font-size: medium;
    font-weight: bold;
}

.userMenuProfileItemsEmail {
    font-size: 12px;
    width: 25ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 2px;
}

.userMenuProfileItemsSettings {
    font-size: small;
    padding-top: 8px;
    cursor: pointer;
}

.permissionIcons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 8px;
}

.permissionIconItems {
    padding-left: 5px;
    padding-right: 5px;
}

//Sidebar Start

.tenantSelect {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

}

// .mat-mdc-form-field-infix {
//     padding-top: 5px !important;
//     padding-bottom: 5px !important;
//     min-height: 0px;
// }

// .mat-mdc-option {
//     min-height: 30px !important;
// }

.container {
    width: 100%;
}

.sidenav-content {
    display: flex;
    height: calc(100vh - 80px);
    align-items: center;
    justify-content: center;
}

.sidenav {
    padding: 0px;
    background-color: $accentlight !important;
}

.sidebarMainDiv {
    display: flex;
    flex-direction: column;
}

.sidebarMenuButton {
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-left: 5px solid $accentlight;
    border-radius: 0;
    font-size: 18px;
    font-family: 'Arial';
    color: white;
    background-color: $accentlight;
    cursor: pointer;
    text-align: left;
}

.sidebarMenuButtonSelected {
    background-color: $acc2 !important;
    border-left: 5px solid $acc2 !important;
}

.sidebarMenuButton:hover {
    border-left: 5px solid $acc2;
    background-color: $acc1;
}

.sidebarMenuButtonLower {
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-left: 5px solid $accentlight;
    border-radius: 0;
    font-size: 16px;
    font-family: 'Arial';
    color: white;
    background-color: #0c2875;
    cursor: pointer;
    text-align: left;
}

.sidebarMenuButtonLower:hover {
    border-left: 5px solid $acc2;
    background-color: $acc1;
}

.dockMenuSlideToggleLabel {
    color: white !important;
}

.dockMenuSlideToggle {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.viewport {
    height: calc(100vh - 80px);
    width: 100%;
    border: none;
    margin: 0px;
    padding: 0px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $accentlight;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}



//Sidebar End

//Page generics start

.divHeaderLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}


//Page generics end


//Contact us Start


//Contact us end





$accentdark: #00AB8E;
/* Mostly all the buttons */
$accentlight: #021857;
/* Mostly all the menu backgrounds */
$hoverlight: #5B9BD5;
/* Mostly hover itmes */
$disabledDark: #75787B;
$tableHeader1: #B0B4C3;
$tableHeader2: #E3E7EB;

$acc1: #5B9BD5;
$acc2: #00AB8E;
$acc3: #021857;
$acc4: #75787B;

.smallStats {
    min-width: 250px;
    height: fit-content;
}

.cardsDiv {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.cardHeader {
    font-family: 'Arial';
    font-size: 12px;
    font-weight: bold;
    color: $acc4;
    user-select: none;
    padding-bottom: 8px;
}

.cardValue {
    font-family: 'Arial';
    font-size: 24px;
    font-weight: bold;
    color: $acc3;
    user-select: none;
}

.cardSmallHeader {
    font-family: 'Arial';
    font-size: 11px;
    color: $acc4;
    user-select: none;
}

.cardSmallValue {
    font-family: 'Arial';
    font-size: 18px;
    font-weight: bold;
    color: $acc3;
    user-select: none;
    padding-bottom: 8px;
}

.cardTopSection {
    display: flex;
    justify-content: space-between;
}

.statLine1Pos {
    font-family: 'Calibri';
    color: #00AB8E;
    user-select: none;
}

.statLine1Neg {
    font-family: 'Calibri';
    color: $negValue;
    user-select: none;
}

.statLine1 {
    font-family: 'Calibri';
    color: $accentlight;
    user-select: none;
}

.statLine2 {
    font-family: 'Calibri';
    color: $acc4;
    user-select: none;
}

.roundIconDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: $acc2;
    margin-left: 10px;
}

.mainTextbox {
    width: 100%;
    position: relative;
    max-width: 220px;
    text-align: left;
    padding: 3px 4px;
    vertical-align: baseline;
    box-sizing: border-box;
    border-radius: 3px;
    border: 2px solid #adadad;
    height: 33px;
    background-color: white;
    color: #333333;
}

.blueButton {
    background-color: $accentdark;
    border: 0px solid;
    border-radius: 3px;
    padding: 10px 10px 10px 10px;
    margin: 3px 3px 3px 3px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}

.blueButton:hover {
    background-color: $hoverlight;
}

.blueButton:disabled {
    background-color: $disabledDark;
}

.mat-mdc-snack-bar-container {
    &.blue-snackbar {
        --mdc-snackbar-container-color: #2196f3;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
        --mdc-text-button-label-text-color: #fff;
        --mat-snack-bar-button-color: #fff;
    }

    &.green-snackbar {
        --mdc-snackbar-container-color: #26AE60;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
        --mdc-text-button-label-text-color: #fff;
        --mat-snack-bar-button-color: #fff;
    }

    &.red-snackbar {
        --mdc-snackbar-container-color: #f32121;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
        --mdc-text-button-label-text-color: #fff;
        --mat-snack-bar-button-color: #fff;
    }
}


// .smallSelect>.mat-mdc-form-field-infix {
//     padding: 0px !important;
//     padding-top: 20px !important;
// }

.smallSelect>div>div>div {
    padding: 0px !important;
}

.smallSelect>div>div>div {
    height: 30px !important;
    min-height: 30px !important;
}

.toastNotification {
    background-color: $acc1;
}

.mat-mdc-menu-panel.advDateManu { width: 400px; max-width: none !important; }


.wfApprovalTabLabel{
    justify-content: start !important;
}